var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", align: "center", width: "100", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "fullname",
          align: "center",
          width: "100",
          label: "用户名",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "mobile",
          align: "center",
          label: "手机号",
          width: "160",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logistics_name",
          align: "center",
          label: "城市仓",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.logistics_name || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "bank_username",
          align: "center",
          label: "城市仓持卡人",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.bank_username || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "pay_at",
          align: "center",
          label: "支付时间",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "order_amt",
          align: "center",
          label: "订单金额",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "order_no",
          align: "center",
          label: " 订单号 ",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "purchase_deduction_amount",
          align: "center",
          label: "服务币金额",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "purchase_fee_account_amount",
          align: "center",
          label: "服务币余额",
          "min-width": "150",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "type_txt",
          align: "center",
          label: "类型",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }