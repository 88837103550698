<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column type="index" align="center" width="100" label="序号">
    </el-table-column>
    <el-table-column
      prop="fullname"
      align="center"
      width="100"
      label="用户名"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="mobile" align="center" label="手机号" width="160">
    </el-table-column>
    <el-table-column
      prop="logistics_name"
      align="center"
      label="城市仓"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistics_name || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="bank_username"
      align="center"
      label="城市仓持卡人"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.bank_username || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="pay_at"
      align="center"
      label="支付时间"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="order_amt"
      align="center"
      label="订单金额"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="order_no"
      align="center"
      label=" 订单号 "
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="purchase_deduction_amount"
      align="center"
      label="服务币金额"
      min-width="120"
    >
    </el-table-column>
    <el-table-column
      prop="purchase_fee_account_amount"
      align="center"
      label="服务币余额"
      min-width="150"
    >
    </el-table-column>
    <el-table-column
      prop="type_txt"
      align="center"
      label="类型"
      min-width="100"
      show-overflow-tooltip
    >
    </el-table-column>
  </el-table>
</template>

<script>
import { CONSUMPTION_DETAILS_TYPE } from "../../utils/enum";
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { CONSUMPTION_DETAILS_TYPE };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
