<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
      <div class="form-item">
        <el-select
          v-model="formData.logistic_business_id"
          clearable
          placeholder="选择集配中心"
        >
          <el-option
            v-for="item of logisticList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="form-item">
        <el-select
          v-model="formData.store_logistic_id"
          filterable
          clearable
          placeholder="选择城市仓"
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item">
        <el-input
          v-model="formData.member_name"
          clearable
          placeholder="输入用户名"
        >
        </el-input>
      </div>
      <div class="form-item">
        <el-input
          v-model="formData.member_mobile"
          clearable
          placeholder="输入用户手机号"
        >
        </el-input>
      </div>
      <div class="form-item">
        <el-date-picker
          @change="onChangeTime"
          v-model="pay_time"
          :time-arrow-control="true"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="—"
          start-placeholder="支付时间起"
          end-placeholder="支付时间止"
        >
        </el-date-picker>
      </div>
      <div class="form-item">
        <el-input
          v-model="formData.bank_username"
          clearable
          placeholder="输入城市仓持卡人姓名"
        >
        </el-input>
      </div>
      <div class="form-item">
        <el-input
          v-model="formData.order_no"
          clearable
          placeholder="输入订单号"
        >
        </el-input>
      </div>
      <div class="form-item">
        <el-select
          v-model="formData.status"
          filterable
          clearable
          placeholder="类型"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list v-loading="loadingData" :tableData="tableData"></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import TableList from "./modules/table-list/index.vue";
import { postLogisticsList } from "@/api/member/index";
import { postConsumeLogisticsListExport } from "@/api/export/center";
import { CONSUMPTION_DETAILS_TYPE } from "./utils/enum/index";

export default {
  name: "consumptionDetails",
  components: {
    TableList,
  },
  data() {
    return {
      loadingData: false,
      disabledExport: false,
      CONSUMPTION_DETAILS_TYPE,
      typeList: Object.values(CONSUMPTION_DETAILS_TYPE), // 类型列表
      logisticList: [], // 集配中心列表
      cityStoreList: [], // 城市仓列表
      pay_time: [], //支付时间
      formData: {
        logistic_business_id: "", // 集配中心id
        store_logistic_id: "", // 城市仓id
        pay_at_start: "", // 支付开始时间
        pay_at_end: "", // 支付结束时间
        member_name: "", // 用户名
        member_mobile: "", // 用户手机号
        bank_username: "", // 持卡人姓名
        order_no: "", // 订单号
        status: "", // 类型
        page: 1,
        pageSize: 20,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
    };
  },
  watch: {
    /**
     * 如果集配中心发生改变，则重置城市仓数据
     */
    "formData.logistic_business_id"(val) {
      this.cityStoreList = []; // 清空所选城市仓
      this.formData.store_logistic_id = "";
      this.ajaxGetCityStoreList(val);
    },
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxPostTableData();
      this.ajaxGetLogisticList();
      this.ajaxGetCityStoreList();
    },
    /**
     * 获取集配中心列表
     */
    async ajaxGetLogisticList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticList = data;
      } catch (err) {
        console.log("ajax ajaxGetLogisticList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      }
    }, 300),
    /**
     * 查询列表数据
     */
    async ajaxPostTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await postLogisticsList(params);
        const { total, data: list } = data;
        this.total = total;
        this.tableData = list;
      } catch (err) {
        console.log("ajax postLogisticsList err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 支付时间发生改变
     */
    onChangeTime(e) {
      if (e && e.length > 0) {
        this.formData.pay_at_start = e[0];
        this.formData.pay_at_end = e[1];
      } else {
        this.formData.pay_at_start = "";
        this.formData.pay_at_end = "";
      }
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.ajaxPostTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxPostTableData();
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        const params = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
          user_name: username,
        };
        delete params.page;
        delete params.pageSize;
        await postConsumeLogisticsListExport(params);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postConsumeLogisticsListExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxPostTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxPostTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
