/** 消费明细类型 */
export const CONSUMPTION_DETAILS_TYPE = {
  all: {
    value: 0,
    label: "全部",
  },
  orderDeduction: {
    value: 1,
    label: "下单抵扣",
  },
  reviewAndReturn: {
    value: 2,
    label: "复核返还",
  },
};
